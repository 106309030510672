.pageWrapper {
  background: linear-gradient(90deg, #000000, #737373);
}
/* .pageContainer {
  position: relative;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
} */
 .card-all-style{
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color:#fff;            
  color: #000;
 }
 .d-flex-center-center{
  display: flex;
  align-items: center;
  justify-content: center;
 }
.pageContainerRight{
  background-color: white;
  padding: 50px 130px;
  border-radius: 10px;
}
.form-wrapper {
  position: relative;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  max-width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-form-item-required {
  font-weight: bold !important;
}

.buttonContainer {
  width: 300px !important;
  background-color: #0463EF;
  border: none !important;
  padding: 10px;
  margin-top: 12px !important;
  color: white !important;
  border-radius: 50px !important;
}

.submitContainer {
  border-color: transparent !important;
  background-color: transparent !important;
}

.emailField {
  width: 250px !important;
  height: 30px !important;
  margin-top: 15px !important;
}

.content-wrapper {
  position: relative;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 454.3999938964844px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-content: center;
}

/*------------common-----------------*/
.dispaly-img {
  height: 200px;
  width: 200px;
}

button.ant-switch.block-check.ant-switch-checked {
  background-color: red !important;
}

.ant-table-thead>tr>th {
  background-color: #E8E8E8;
}

span {
  font-weight: 500;
}

.common-btn {
  padding: 8px 20px;
  background-color: #0463EF;
  color: #fff;
  border-radius: 50px;
  font-weight: 700;
}

.common-btn:hover,.ant-tooltip-blue .ant-tooltip-inner,.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background-color: rgba(4, 99, 239, 0.8);
  color: #ffffff;
}

.common-btn2 {
  padding: 0px 30px;
  background-color: #0463EF;
  color: #fff;
  border-radius: 50px;
}

.common-btn2:hover {
  background-color: rgba(4, 99, 239, 0.8);
  color: #ffffff;
}

.common-btn3 {
  margin: 10px;
  background-color: #0463EF;
  color: #fff;
  border-radius: 10px;
}

.common-btn3:hover,
.common-btn3:active,
.common-btn3:focus {
  color: rgba(4, 99, 239, 0.8);
  border-color: rgba(4, 99, 239, 0.8);
  background-color: #ffffff;
}

.confirm-span {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.common-btn-color {
  width: 100%;
  border-radius: 50px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #000000;
  color: #fff;
}

.save-btn {
  padding: 0px 30px;
}

/* .user-table .ant-table-container table>thead>tr:first-child th:last-child,
.user-table .ant-table-tbody>tr>td:last-child {
  text-align: right !important;
} */

.profile-photo .ant-btn {
  padding: 0px 20px;
  border: none;
}

.profile-photo .ant-btn:hover {
  border: none;
}

.profile-photo p {
  color: #E8E8E8;
}

.ant-switch {
  width: 120px;
}

.ant-switch-checked {
  background: #0463EF;
}

.form-col-gap .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}

.profile-photo .user-photo .anticon svg {
  font-size: 80px;
}

.user-photo {
  background: transparent;
  color: #000000;
  border: 10px solid #000000;
}

/*------------add resaurants---------------*/
.add-r-main {
  border-radius: 5px;
}

.add-r-head {
  background-color: #E8E8E8;
  border-radius: 5px 5px 0 0;
}

.add-r-form .ant-row {
  display: flex;
}

.logoMobile {
  margin-bottom: 20px;
}

#basic_remember {
  width: 300px;
  margin-top: 10px;
}

.loginWrapper {
  background-color: white;
  position: absolute;
  z-index: 999;
  top: 30%;
  left: 40%;
  padding: 20px;
  border-radius: 10px;
}


/*-------- top-card --------*/
.top-icon p {
  font-size: 20px;
  font-weight: 600;
}

.top-icon svg {
  font-size: 40px;
}



/*----------- Payment Modal -------------*/
.payment-modal .ant-modal-footer .ant-btn-default {
  display: none !important;
}


/*-------- Oppgjor Table -------*/
.op-table .user-table table {
  table-layout: fixed !important;
}

.op-table .user-table table .ant-table-thead>tr>th,
.op-table .user-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
  padding: 16px 5px;
}

.op-table .user-table .ant-table-container table>thead>tr:first-child th:last-child,
.op-table .user-table .ant-table-tbody>tr>td:last-child {
  text-align: left;
}

/*-----------table----------------*/
.ant-table-thead>tr>th {
  padding: 10px 14px;
}


.loginConatiner {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.bg {
  background-image: url("./assets/bg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.ant-col-offset-8 {
  margin-left: 0px !important;
}

/*------------form common start----------*/

.ant-col-16 {
  max-width: 100% !important;
}

.ant-btn-primary {
  background-color: #0463EF !important;
  border-radius: 20px !important;
  width: 150px;
  border-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------form common end----------*/

.ant-modal-content {
  width: 500px;
  margin: auto;
}

.ant-modal-footer {
  padding: 10px 16px !important;
  text-align: center !important;
}

.ant-form-item-label {
  text-align: left;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

/*-------------login-------------*/
.ant-form-item {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
}

.passwordContainer {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

#login div {
  display: flex;
}

form .ant-row {
  display: inline-block;
}

.graph-display .ant-card {
  padding: 15px 0;
}

.graph-display .ant-card-body {
  width: 100%;
}

.ant-col-8 {
  max-width: 100% !important;
}

.side-nav {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

