.errorMsg{
  margin-top: 10px;
  color: red;
  font-size: 14px;

}
.abcd > .ant-btn-default{
  color: blue;
}
.model-button{
  margin: 8px !important;
  border-radius: 5px !important;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.pointValue{
  font-size:16px ;
   padding:460px;
}
.tfl-save-btn{
  width: 20px !important;
}
.ranges{
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: bold !important;
}
.rangespan{
  color: red !important;
  margin-right: 4px !important;
  font-weight: bold !important;
  margin-left: 15px !important;
}
.rangespan2{
  color: red !important;
  margin-right: 4px !important;
  font-weight: bold !important;
  margin-left: 35px !important;
}
.rangespan3{
  color: red !important;
  margin-right: 4px !important;
  font-weight: bold !important;
  margin-left: 210px !important;
}
.container{
  display: flex !important;
  justify-content: center !important;
}
.myCol{
  padding: 0 5px !important;
}