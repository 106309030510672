/*-----------------------Common-------------------------------*/
p{
    font-weight: 500;
}
/*-----------------------------Main------------------------------*/
.main{
    height: 100vh;
    width: 100vw;
    overflow:hidden;
}
p,span, th,tr{
    font-size: 14px;
}
h4 {
    padding-left: 5px;
    font-size: 18px;
}
svg{
    font-size: 18px;
}
.main .ant-divider-horizontal{
    border: none;
}
/*--------------------------Top Header---------------------------*/
.main .ant-layout-header {
    padding: 0 20px !important;
    z-index: 9;
    background: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px 0px #b6b8ba;
    justify-content: space-between;
}
/*-----------------------Side Navigation------------------------*/
.side-nav {
    margin: 10px 0;
    background: transparent;
    color: #ffffff;
    /* overflow-y: scroll; */
    /* transition: all 0.5s; */
    /* height: 90vh; */
}
.side-nav a, .side-nav a:hover{
    color: #ffffff;
}
.side-nav .ant-menu-item:hover{
    color: #ffffff;
    background-color: rgba(236, 236, 236, .1) ;
}
/* .side-nav:hover {
    overflow-y: scroll;
    transition: all 0.5s;
} */
.ant-menu-vertical {
    border-right: none;
}
.side-nav .ant-menu-item-selected{
    background-color: #0463EF !important;
    color: #ffffff;
}

/*--------------orderperrestaurant------------*/
.impPop p{
    font-weight: 400;
}

/*----------------------------Body-----------------------------*/
.body-main{
    padding: 20px;
    /* flex-direction: column; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.body-main .ant-space-item .anticon svg{
    color: #111111;
    font-size: 20px;
}
.ant-card-body {
    width: 340px;
    padding: 0px 20px;
}

.ant-card-body p{
    margin-bottom: 0;
}
.ant-card {
    box-shadow: 0px 2px 5px 0px #b6b8ba;
    width: 100%;
    padding: 30px 0;
    border-radius: 10px;
    transition: all 0.3s;
}
.top-card .ant-card:hover, .top-card tr.ant-table-row:hover td, .top-card tr.ant-table-row:hover svg{
    background-color: #0463EF !important;
    transition: all 0.5s;
    color: #ffffff !important;
}
.dashtable-o{
    background-color: transparent;
}
.ant-layout-sider {
    background: linear-gradient(90deg, #000000, #737373);

}
.dashtable-r , .dashtable-o {
    flex-direction: column;
}
.dashtable-r .ant-table-wrapper svg, .dashtable-o .ant-table-wrapper svg{
    color: #111111;
}
.graph-display{
    display: flex;
    justify-content: space-between;
}
.graph-display .ant-card{
    width: 470px;
    height: 230px;
}
.graph-display-big .card{
    width: 800px;
    /* height: 400px; */
}
/*-----------------------drop down-----------------------------*/


/*--------------------------UserList-----------------------------*/
.input-box{
    width:1200px;
}
.user-table{
    width: 100%;
}
.user-table2{
    width: 100%;
}


/*---------------------------Scroll----------------------------*/
/* width */
.vertical-scroll::-webkit-scrollbar, ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}
  
/* Track */
.vertical-scroll::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
   
/* Handle */
.vertical-scroll::-webkit-thumb, ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3); 
    border-radius: 10px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0463EF; 
}

