.center {
    margin: auto;
    width: 90%;
    padding: 10px;
  }
.abcd > .ant-btn-default{
    color: blue;
}
.model-button{
    margin: 8px !important;
    border-radius: 5px !important;
}
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  .pointValue{
    font-size:16px ;
     padding:460px;
  }