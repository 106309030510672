.postDescription{
    color: gray !important;
}
.postDescription2{
    color: black !important;
    font-size: 16px !important;
    font-weight: 500;
}
.postTitle{
    min-height: 380px !important;
}
.postTitle > .ant-card-head{
    font-size: 25px !important;
    font-weight: 600;
}
.postTitle > .ant-card-actions{
    /* position: sticky; */
     bottom: 0px;
     background:"#fff5f0"
}
.postTitle{
    padding: inherit !important;
}