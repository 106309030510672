body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #e3e3e3 !important;
}
.op-table .user-table table .ant-table-thead>tr>th, .op-table .user-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,.ant-table-cell{
  text-align: center !important;
}
table p{
  font-weight: 400;
}
a.ant-typography, .ant-typography a,a.ant-typography:hover, .ant-typography a:hover,a.ant-typography:focus, .ant-typography a:focus{
  color: #000 !important;
}

.deliveryListCard .ant-card-body{
  width: 100% !important;
}
.settingPage .ant-form-item-label{
  text-wrap: wrap !important;
}
.settingPage .ant-form-item-label > label{
  height: 100% !important;
}

/* all media query here only */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .viewTfl .ant-row.flex.display-data{
    padding: 20px !important;
  }
  .pointSystemSetting .ant-form-item-label > label{
    height: auto  !important;
  }
  .pageContainer {
    display: none;
  }
  .pageContainer img{
    width: 200px;
  }
  .btnHistory{
    flex-direction: column;
    height: fit-content !important;
  }

  .ant-table-cell{
    text-wrap: wrap !important;
  }
  .pageContainerRight,.btnHistory,.ant-picker,.userHistoryPage .col-4,.ant-modal-content,.dispaly-img .ant-image-img ,.ant-picker{
    width: 100% !important;
  }

  .dispaly-img {
    height: 100px !important;
    width: 100px !important;
}
  .logoMobile {
    display: block !important;
  }

  .password {
    margin-top: 10px;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    max-width: 55px !important;
    min-width: 55px !important;
  }

  .topCardRow,.pageWrapper,.userHistoryPage .col-4,.orderPerReDate ,.flexColumn{
    flex-direction: column !important;
  }
  .graph-display{
    justify-content: center !important  ;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,.body-main .form-r-main {
    padding: 5px !important;
  }

  .user-table .ant-table-content {
    overflow-x: scroll;
  }
  .body-main h3 {
    margin-bottom: 0;
    font-size: 14px;
}

  p,
  span,
  th,
  tr,.ant-form-item-label > label{
    font-size: 12px !important;
  }
  .addDriverPage .ant-form-item {
    margin-bottom: 5px;
}
.addDriverPage .add-r-head, .addDriverPage .form-r-main,.add-r-main .add-r-head{
  padding: 10px !important;
} 
.addDriverPage #nest-messages, #nest-messages,.add-r-main .display-data,.noTopPadding{
  padding: 0 !important;
}
.addDriverPage h3{
  margin-bottom: 0 !important;
}
  .ant-card-body {
    width: auto !important;
  }

  .top-card .ant-col,.pageContainer,.pageContainerRight  {
    width: 100% !important;
  }

  .graph-display .ant-card {
    width: auto !important;
    height: auto !important;
  }

  .logo img {
    width: 40px !important;
  }
}

@media only screen and (min-device-width: 550px) and (max-device-width: 1100px) {
  .logo img {
    width: 40px !important;
    margin-top: 10px;
  }
  h4 {
    font-size: 15px !important;
}
  .top-card .ant-card {
    width: 200px;
    padding: 15px 0;
  }

  .top-card .ant-card-body {
    width: auto;
  }

  .top-icon svg {
    font-size: 30px !important;
  }

  .graph-display .ant-card {
    width: 420px !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px){
  .logo img{
    width: 40px !important;
    margin-top: 10px;
  }
  .top-card .ant-card-body {
  width: 230px !important;
}
.graph-display-big .card{
  width:460px !important ;
}
}
@media only screen and (min-device-width: 1366px) and (max-device-width: 1400px){
  .logo img{
    width: 40px !important;
    margin-top: 10px;
  }
  .top-card .ant-card-body {
  width: 260px !important;
}
.graph-display .ant-card {
  width: 360px !important;
}
.graph-display-big.graph-display .ant-card {
  width: 550px !important;
}
}

@media only screen and (min-device-width: 2560px){
  .top-card .ant-card-body {
  width: 550px !important;
}
.graph-display .ant-card {
  width: 750px !important;
}
.graph-display-big.graph-display .ant-card {
  width: 1100px !important;
}
}